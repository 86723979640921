import { graphql, Link, navigate } from "gatsby";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import SeoHelmet from "../components/Seo/SeoHelmet";
import MaxContent from "../layout/MaxContent";
import { componentBuilder } from "../helpers/componentsHelper";
import { useDevice } from "../components/Hooks";
import MakeSlug from "../helpers/makeSlug";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import { GatsbyImage } from "gatsby-plugin-image";
import { translate } from "../contexts/i18n.context";
import { IBlog } from "../components/Articles/Article_Listing/ArticleListing";
import { componentsOutsideBlocks } from "../helpers/componentsOutsideBlocks";
import SnackbarComponent from "../components/Snackbar/Snackbar";
import { LocationContext } from "../helpers/locationContext";
import Rating from "../components/Rating/Rating";
import { GlobalIcon } from "get-life-storybook-ts";

export default (data: any) => {
  const [isMobileView, setIsMobileView] = useState<Boolean>();
  const { isMobile } = useDevice();
  const blogData = data.data.blog;
  const sidebarData = data.data.allStrapiBlog.nodes;
  const sidebarDataFormat = sidebarData
    .sort(
      (a: IBlog, b: IBlog) =>
        new Date(a.publishedAt).valueOf() - new Date(b.publishedAt).valueOf()
    )
    .reverse()
    .slice(0, 5);
  const relatedArticlesFormat = (() => {
    let articles = [];
    const RelatedArticles = blogData.RelatedArticles;
    if (RelatedArticles.length > 0) {
      RelatedArticles.forEach((a) => {
        const { ArticleId } = a;
        if (ArticleId) {
          const foundArticle = sidebarData.find(
            (d) => d.strapiId.toString() === ArticleId
          );
          if (foundArticle) articles.push(foundArticle);
        }
      });
    } else {
      articles = sidebarData
        .filter(
          (d) =>
            d.Category === blogData.Category &&
            d.id !== blogData.id &&
            !sidebarDataFormat.some((e) => e.id === d.id)
        )
        .sort(
          (a: IBlog, b: IBlog) =>
            new Date(a.publishedAt).valueOf() -
            new Date(b.publishedAt).valueOf()
        )
        .reverse();
    }
    return articles.slice(0, 3);
  })();

  const navbarComponent = blogData.Navbar;
  const blogContentComponents = blogData.Content;
  const seoComponent = blogData.SEO;
  const footerComponent = blogData.Footer;
  const blogContent = componentBuilder(blogContentComponents);

  const [input, setInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (search !== "") {
      navigate(`/blog/?q=${search.replace(/\s/g, "+")}`);
    }
  }, [search]);

  useEffect(() => {
    if (typeof window !== undefined) {
      const hash = window.location.hash;
      if (hash.includes("#")) {
        console.log("scroll");
        window.scrollBy(0, -130);
      }
    }
  });

  const placeholder = {
    es: "Buscar",
    axa_wl_es: "Buscar",
    it: "Ricerca",
    en: "Search",
    fr: "Recherche"
  }[process.env.GATSBY_APP_LOCALE]

  useEffect(() => {
    isMobile ? setIsMobileView(true) : setIsMobileView(false);
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearch(input);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleClick = () => {
    setSearch(input);
  };
  return (
    <LocationContext.Provider value={data.location}>
      {/* @ts-ignore */}
      <SeoHelmet data={seoComponent} locale={blogData.locale} customUrl={data.location.pathname}/>
      {navbarComponent && <Navbar data={navbarComponent} />}
      <div
        itemProp="itemReviewed"
        itemScope
        itemType="https://schema.org/CreativeWorkSeries"
      >
        <span itemProp="name" className="hidden">
          {blogData?.Header?.Title}
        </span>
        <TertiaryHeader
          data={blogData.Header?.Title ? blogData.Header : blogData}
        />

        <MaxContent size={"mobileResponsive"}>
          <div className="grid lg:grid-cols-12 gap-10 my-[80px] blog-template">
            {/* sidebar */}
            <div className="lg:col-span-4 order-last lg:order-first bg-inactive-gray px-[20px] h-min">
              <div className="mt-[30px]">
                <Link to="/blog/" className="no-underline">
                  <div className="flex flex-row items-center gap-[15px]">
                    <GlobalIcon
                      iconName="ArrowBackIcon"
                      size="XS"
                      color="var(--primary-color)"
                    />
                    <>Blog</>
                  </div>
                </Link>
              </div>

              <div className="relative  mb-[20px] mt-[30px]">
                <input
                  className="p-3 border rounded-md"
                  type={"text"}
                  placeholder={placeholder}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleOnChange(e)
                  }
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e)
                  }
                />
                <span className="absolute top-0 right-3 h-full flex items-center">
                  <span
                    role="button"
                    tabIndex={0}
                    className="p-[4px]"
                    onClick={() => handleClick()}
                    onKeyDown={(e) => {
                      if (e.key === " " || e.key === "Enter") {
                        e.preventDefault();
                        handleClick();
                      }
                    }}
                  >
                    <GlobalIcon
                      iconName="SearchIcon"
                      size="XXS"
                      color="var(--primary-color)"
                    />
                  </span>
                </span>
              </div>

              <p className="h5-default pt-[30px] text-black border-t-[2px] border-t-purple mb-[40px]">
                {translate("articles.last")}
              </p>

              <div className="flex flex-col gap-[25px] mb-[48px]">
                {sidebarDataFormat.map((node: any) => {
                    let url = `/blog/${node.CustomUrl ?? MakeSlug(node.Title)}`;

                    if (node.Category) {
                      url = `/blog/${MakeSlug(node.Category)}/${
                        node.CustomUrl ?? MakeSlug(node.Title)
                      }`;
                    }

                    if (node.Category === "Conseil_et_fiches_pratiques") {
                      url = `/blog/conseils/${
                        node.CustomUrl ?? MakeSlug(node.Title)
                      }`;
                    }

                    if (node.Category === "Edito_et_actus") {
                      url = `/blog/edito/${
                        node.CustomUrl ?? MakeSlug(node.Title)
                      }`;
                    }

                    return (
                      <Link
                        key={node.id}
                        to={url.replace("//", "/")}
                        className="no-underline hover:underline hover:text-purple text-black font-normal text-link-sm"
                      >
                        {node.Title}
                      </Link>
                    );
                  })}
              </div>
              {relatedArticlesFormat.length > 0 ? (
                <>
                  <p className="h5-default pt-[30px] text-black border-t-[2px] border-t-purple mb-[40px]">
                    {translate("articles.related")}
                  </p>
                  <div className="flex flex-col gap-[25px] mb-[48px]">
                    {relatedArticlesFormat.map((node: any) => {
                      let url = `/blog/${
                        node.CustomUrl ?? MakeSlug(node.Title)
                      }`;

                      if (node.Category) {
                        url = `/blog/${MakeSlug(node.Category)}/${
                          node.CustomUrl ?? MakeSlug(node.Title)
                        }`;
                      }

                      if (node.Category === "Conseil_et_fiches_pratiques") {
                        url = `/blog/conseils/${
                          node.CustomUrl ?? MakeSlug(node.Title)
                        }`;
                      }

                      if (node.Category === "Edito_et_actus") {
                        url = `/blog/edito/${
                          node.CustomUrl ?? MakeSlug(node.Title)
                        }`;
                      }

                      return (
                        <Link
                          key={node.id}
                          to={url.replace("//", "/")}
                          className="no-underline hover:underline hover:text-purple text-black font-normal text-link-sm"
                        >
                          {node.Title}
                        </Link>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>

            {/* blog content */}
            <div className="lg:col-span-8">
              {blogData.PrimaryImage ? (
                <GatsbyImage
                  className="w-full h-[270px] mb-10"
                  image={
                    blogData.PrimaryImage?.file?.childImageSharp
                      ?.gatsbyImageData
                  }
                  alt={blogData.PrimaryImage?.alternativeText}
                />
              ) : (
                <img
                  src={blogData.ImageURL}
                  alt={blogData.ImageAlt}
                  className="w-full h-[270px] mb-10 object-cover"
                />
              )}
              <div>
                <div className="flex flex-col gap-[32px] overflow-visible">
                  {blogContent?.map((block, id) => {
                    const type = block?.props?.data?.__typename;
                    if (componentsOutsideBlocks.includes(type)) return null;
                    return (
                      <div
                        className={
                          type !==
                            "StrapiComponentComponentEmbeddedCalculator" &&
                          type !== "StrapiComponentComponentContentTable" &&
                          "blog-content"
                        }
                        key={id}
                      >
                        {block}
                      </div>
                    );
                  })}
                  {blogData.UserCanVote === true && (
                    <Rating
                      blogId={blogData.strapiId}
                      totalRating={blogData.TotalRating ?? 0}
                      totalVotes={blogData.TotalVotes ?? 0}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </MaxContent>
        {footerComponent && <Footer data={footerComponent} />}
        {blogData.HasStickyBanner !== false &&
          process.env.GATSBY_APP_LOCALE === "es" && (
            <SnackbarComponent
              data={{
                TitleSnackbar: "¡OFERTA LIMITADA!",
                Subtitle:
                  "Paga 1€ al mes, los 3 primeros meses. Hasta el 31 de Julio.",
                URL: "https://life5.es/calcular-seguro-vida/",
                CTA: "Calcular mi seguro de vida",
              }}
            />
          )}
          {blogData.HasStickyBanner !== false &&
          process.env.GATSBY_APP_LOCALE === "axa_wl_es" && (
            <SnackbarComponent
              data={{
                TitleSnackbar: "¡OFERTA LIMITADA!",
                Subtitle:
                  "Paga 1€ al mes, los 3 primeros meses. Hasta el 31 de Julio.",
                URL: "https://axa.life5.es/calcular-seguro-vida/",
                CTA: "Calcular mi seguro de vida",
              }}
            />
          )}
        {blogData.HasStickyBanner !== false &&
          process.env.GATSBY_APP_LOCALE === "fr" && (
            <SnackbarComponent
              data={{
                TitleSnackbar: "Offre Limitée : Jusqu'au 31 Juillet",
                Subtitle: "1€ par mois pour les 3 premiers mois",
                URL: "https://app.life5.fr/calculator/1",
                CTA: "Devis en 2 minutes",
              }}
            />
          )}
        {blogContent?.map((block, id) => {
          const type = block?.props?.data?.__typename;
          if (componentsOutsideBlocks.includes(type))
            return <React.Fragment key={id}>{block}</React.Fragment>;
          return null;
        })}
      </div>
    </LocationContext.Provider>
  );
};

export const blogQuery = graphql`
  query ($id: String!) {
    allStrapiBlog {
      nodes {
        id
        strapiId
        Title
        CustomUrl
        Category
        Description
        PrimaryImage {
          alternativeText
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ImageURL
        ImageAlt
        createdAt
        updatedAt
        publishedAt
      }
    }
    blog: strapiBlog(id: { eq: $id }) {
      id
      strapiId
      Title
      Category
      Description
      locale
      HasStickyBanner
      UserCanVote
      TotalRating
      TotalVotes
      PrimaryImage {
        alternativeText
        file {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      ImageURL
      ImageAlt
      Header {
        Title
      }
      RelatedArticles {
        ArticleId
      }
      updatedAt
      SEO {
        SeoTitle
        SeoDescription
        DoNotIndex
        excludeSiteMap
        DoNotFollow
        Schema
        CanonicalTag
        TwitterImageUrl
        FacebookImageUrl
        ImageUrl
        Image {
          file {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
        Links {
          HrefLang
          Href
        }
      }
      Navbar {
        DropdownLabel
        DropdownLinks {
          Label
          Url
        }
        Links {
          Label
          Url
        }
        Phone
        HideAccount
        HidePhone
        AccountLabel
        AccountIcon {
          file {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        AccountUrl
        ButtonLabel
        ButtonUrl
        idButton
      }
      Footer {
        group_title_1
        group_title_2
        group_title_3
        group_title_4
        group_links_1 {
          Label
          Url
        }
        group_links_2 {
          Label
          Url
        }
        group_links_3 {
          Label
          Url
        }
        group_links_4 {
          Label
          Url
        }
        group_links_bottom {
          Label
          Url
        }
        contact_address
        contact_phone_number
        contact_phone_subtitle
        contact_title
        contact_map_link {
          Label
          Url
          id
        }
        form_header
        form_submit_button_link {
          Label
          Url
        }
        form_text_placeholder
        facebook_url
        instagram_url
        linkedin_url
        twitter_url
      }
      # Dynamic Zone COMPONENT START
      Content {
        __typename
        ... on StrapiComponentBasicText {
          Text
        }
        ... on StrapiComponentBasicHtml {
          id
          Html
        }
        ... on StrapiComponentBasicTitle {
          Title
        }
        ... on StrapiComponentBasicTitleText {
          Title
          Text
          TitleType
        }
        ... on StrapiComponentBasicButton {
          Label
          Url
          Secondary
          idButton
          NewTab
          NoFollow
        }
        ... on StrapiComponentBasicImage {
          SingleImage {
            file {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentLegalTable {
          id
          Columns {
            Title
          }
          Rows {
            Text
          }
        }
        ... on StrapiComponentComponentHubspotForm {
          id
          portalId
          formId
          region
        }
        ... on StrapiComponentComponentSnackbar {
          id
          TitleSnackbar
          Subtitle
          CTA
          URL
          Target
        }
        ... on StrapiComponentComponentEmbeddedCalculator {
          id
          BackgroundColor
          CTAStepOneIcon
          CTAStepOneLabel
          CTAStepTwoIcon
          CTAStepTwoLabel
          CalculatorTitle
          CalculatorUrl
          HasTitle
          SelectTitleType {
            TitleTypeSelection
          }
          HasPhoneValidation
          HasSeriousIllnesses
        }
        ... on StrapiComponentComponentContentTable {
          id
          ShowUnfolded
          ContentTableTitle
          ContentItems {
            AnchorId
            ContentTableItemTitle
            id
          }
        }
        # Dynamic Zone COMPONENT END
      }
    }
  }
`;
